import * as React from "react";
import Heading from "../../elements/Heading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, A11y } from "swiper";

import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";

const Testimonial = ({ data }: { data: Record<string, string> }) => {
  const { text, author } = data;
  return (
    <div tw="pb-12">
      <div tw="whitespace-pre-wrap pb-2 italic">"{text}"</div>
      <div tw="font-bold inline pl-4">- {author}</div>
    </div>
  );
};

const Testimonials = ({ t }: { t: any }) => {
  return (
    <section
      tw="px-4 md:px-6 pt-14 pb-6 mt-8 mx-auto bg-gray-100"
      id="testimonials"
    >
      <Heading t={t} />
      <div tw="container mx-auto">
        <Swiper
          tw="max-w-lg"
          modules={[Autoplay, Pagination, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {t.items.map((item: Record<string, string>, index: number) => (
            <SwiperSlide>
              <Testimonial key={index} data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
