import * as React from "react";
import Heading from "../../elements/Heading";
import ReactPlayer from "react-player/lazy";

const Video = ({ data }: { data: Record<string, string> }) => {
  const { title, description, url } = data;
  return (
    <div tw="w-full flex flex-col sm:max-w-3xl mx-auto">
      <div tw="text-xl pb-4">{title}</div>
      <div tw="relative pt-[56.25%] w-full h-full">
        <ReactPlayer
          width="100%"
          height="100%"
          tw="absolute top-0 left-0"
          url={url}
        />
      </div>
      <div tw="whitespace-pre-wrap pt-2 pb-12">{description}</div>
    </div>
  );
};

const Videos = ({ t }: { t: any }) => {
  return (
    <section tw="bg-black text-white" id="videos">
      <div tw="container px-4 md:px-6 py-14 mx-auto">
        <Heading t={t} isDark />
        <div tw="flex flex-col items-center">
          {t.items.map((item: Record<string, string>, index: number) => (
            <Video key={index} data={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Videos;
