import tw from "twin.macro";

export const Container = tw.section`
  px-4 py-14 bg-primary flex flex-col items-center gap-10 mx-auto
  md:px-6
`;

export const Title = tw.div`
  text-white text-center text-2xl container
  sm:max-w-lg
  xl:max-w-4xl xl:text-3xl
`;

export const Button = tw.a`
  px-4 py-2 inline-flex items-center justify-center bg-white rounded-sm shadow
  text-primary text-lg whitespace-nowrap
  hover:scale-110
  transition duration-500 ease-in-out
  sm:text-xl
  xl:px-4 xl:py-2 xl:text-2xl
`;
