import * as React from "react";
import {
  ClockIcon as ListIcon,
  CalendarIcon as ArrowIcon,
} from "@heroicons/react/outline";
import {
  CardContainer,
  InnerContainer,
  CardTitle,
  Price,
  Popular,
  IconContainer,
  ListItem,
  Cta,
} from "./styles";

const CardItem = ({ t }: { t: Record<string, any> }) => {
  const isPopular = !!t.popular;

  return (
    <CardContainer>
      <InnerContainer isPopular={isPopular}>
        {isPopular && <Popular>{t.popular}</Popular>}
        <CardTitle>{t.title}</CardTitle>
        {false && (
          <Price>
            <span>{t.price}&euro;</span>
          </Price>
        )}
        {t.items?.map((item: string, index: number) => (
          <ListItem key={index}>
            <IconContainer>
              <ListIcon tw="w-3 h-3" />
            </IconContainer>
            {item}
          </ListItem>
        ))}
        <Cta
          isPopular={isPopular}
          onClick={() => (window.location.href = "#contacts")}
        >
          {t.cta}
          <ArrowIcon tw="w-4 h-4" />
        </Cta>
      </InnerContainer>
    </CardContainer>
  );
};

export default CardItem;
