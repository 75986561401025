import tw from "twin.macro";
import styled from "@emotion/styled";

export const Container = tw.section`
    container px-4 pt-14 mx-auto
    md:px-6
    xl:px-0
`;

export const CardsContainer = tw.div`
    flex flex-wrap -mx-4
`;

export const TextContainer = tw.div`
    px-4 py-14 flex flex-col items-center
    md:px-6
    lg:px-10 lg:flex-grow lg:items-start lg:justify-center lg:w-1/2
    xl:px-20
`;

export const Title = tw.h2`
    mb-6
    text-5xl font-bold
    xl:text-6xl
`;

export const Description = tw.p`
    leading-relaxed whitespace-pre-wrap
    xl:text-2xl
`;

export const CardContainer = tw.div`
    p-4 w-full
    md:w-1/2 md:p-4
    xl:w-1/3
`;

export const InnerContainer = styled.div`
  ${tw`h-full p-6 rounded-sm border-2 border-gray-300 flex flex-col relative overflow-hidden justify-end`};
  ${({ isPopular }: { isPopular: boolean }) => isPopular && tw`border-primary`};
`;

export const CardTitle = tw.h2`
    text-xl tracking-widest mb-1
    xl:mb-auto
`;

export const Price = tw.div`
    text-4xl leading-none flex items-center pb-4 mb-4 border-b border-gray-200
`;

export const Popular = tw.span`
    bg-primary text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl-sm uppercase
`;

export const ListItem = tw.p`
    flex items-center text-gray-600 mb-2
`;

export const IconContainer = tw.span`
    w-4 h-4 mr-2 inline-flex items-center justify-center rounded-full flex-shrink-0
`;

export const Cta = styled.button`
  ${tw`flex justify-between items-center text-white bg-gray-500 border-0 mt-4 py-2 px-4 w-full focus:outline-none rounded-sm`};
  ${({ isPopular }: { isPopular: boolean }) => isPopular && tw`bg-primary`};
`;

const styles = {
  image: tw`
  object-cover object-center max-h-80
  lg:max-h-full
`,
};

export default styles;
