import * as React from "react";
import tw from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import { Tab } from "@headlessui/react";
import styles, {
  Container,
  TextContainer,
  Title,
  Description,
  Certificates,
  ImageContainer,
} from "./styles";

const AboutUs = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="therapist">
      <ImageContainer>
        <StaticImage
          src="../../../images/about.jpg"
          alt={t.imageAlt}
          placeholder="blurred"
          layout="constrained"
          objectFit="cover"
          objectPosition="bottom center"
          css={styles.image}
        />
      </ImageContainer>
      <TextContainer>
        <Title
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="800"
          data-sal-easing="ease-in"
        >
          {t.title}
        </Title>
        <Tab.Group>
          <Tab.List tw="flex mb-6 pb-2">
            <Tab as={React.Fragment}>
              {({ selected }) => (
                <button
                  tw="text-lg border-r-2 border-black pr-4"
                  css={!selected && tw`underline`}
                >
                  {t.meTitle}
                </button>
              )}
            </Tab>
            <Tab as={React.Fragment}>
              {({ selected }) => (
                <button tw="text-lg pl-4" css={!selected && tw`underline`}>
                  {t.certificatesTitle}
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels tw="w-full">
            <Tab.Panel tw="w-full">
              <Description>{t.description}</Description>
            </Tab.Panel>
            <Tab.Panel tw="w-full">
              <Certificates>{t.certificates}</Certificates>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </TextContainer>
    </Container>
  );
};

export default AboutUs;
