import tw from "twin.macro";

export const Container = tw.section`
    w-full h-screen
`;

export const Overlay = tw.div`
    relative w-full h-full bg-opacity-40 bg-black flex justify-center items-center z-10
`;

export const ImageContainer = tw.div`
  w-full
  lg:w-1/2
`;

export const TextContainer = tw.div`
    mx-3 text-center text-white
`;

export const Title = tw.h1`
    mb-14 text-5xl
    sm:text-6xl
    xl:text-7xl
`;

export const PrimaryCta = tw.a`
    bg-primary inline-flex items-center justify-center px-4 py-2 shadow rounded-sm
    text-lg whitespace-nowrap text-white
    sm:text-xl
    hover:bg-white hover:text-primary
    transition duration-500 ease-in-out
`;

export const SecondaryCta = tw.a`
    ml-4 bg-gray-600 inline-flex items-center justify-center px-4 py-2 shadow rounded-sm
    text-lg whitespace-nowrap text-white
    sm:text-xl
    md:ml-8
    hover:bg-white hover:text-gray-600
    transition duration-500 ease-in-out
`;

const styles = {
  image: tw`bg-fixed absolute w-full h-screen object-cover z-0 top-0`,
};

export default styles;
