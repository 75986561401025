import * as React from "react";
import Heading from "../../elements/Heading";
import Card from "./Card";
import { Container, CardsContainer } from "./styles";

const Prices = ({ t }: { t: Record<string, any> }) => {
  return (
    <Container id="prices">
      <Heading t={t} />
      <CardsContainer>
        {t.cards?.map((tCard: Record<string, string>, index: number) => (
          <Card key={index} t={tCard} />
        ))}
      </CardsContainer>
    </Container>
  );
};

export default Prices;
