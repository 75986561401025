import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles, {
  Container,
  TextContainer,
  Overlay,
  Title,
  PrimaryCta,
  SecondaryCta,
  ImageContainer,
} from "./styles";

const Hero = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="home">
      <ImageContainer>
        <StaticImage
          src="../../../images/hero.jpg"
          alt="Gonçalo Roquette"
          placeholder="blurred"
          layout="constrained"
          objectFit="cover"
          objectPosition="center center"
          css={styles.image}
        />
      </ImageContainer>
      <Overlay>
        <TextContainer>
          <StaticImage
            src="../../../images/logo.png"
            alt={t.logoAlt}
            placeholder="blurred"
            layout="constrained"
            width={160}
            tw="w-32"
          />
          <Title
            className="text-shadow"
            data-sal="fade"
            data-sal-delay="300"
            data-sal-duration="800"
            data-sal-easing="ease-in"
          >
            {t.title}
          </Title>
          <PrimaryCta href="#contacts">{t.cta}</PrimaryCta>
          <SecondaryCta href="#therapist">{t.knowMore}</SecondaryCta>
        </TextContainer>
      </Overlay>
    </Container>
  );
};

export default Hero;
