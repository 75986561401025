import * as React from "react";
import {
  Container,
  Title,
  Description,
  TitleDark,
  DescriptionDark,
} from "./styles";

const Heading = ({
  t,
  isDark,
}: {
  t: Record<string, string>;
  isDark?: boolean;
}) => {
  if (isDark)
    return (
      <Container>
        <TitleDark
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="800"
          data-sal-easing="ease-in"
        >
          {t.title}
        </TitleDark>
        {t.description && <DescriptionDark>{t.description}</DescriptionDark>}
      </Container>
    );
  return (
    <Container>
      <Title
        data-sal="fade"
        data-sal-delay="100"
        data-sal-duration="800"
        data-sal-easing="ease-in"
      >
        {t.title}
      </Title>
      {t.description && <Description>{t.description}</Description>}
    </Container>
  );
};

export default Heading;
