import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Heading from "../../elements/Heading";

const Image1 = ({ title }: { title: string }) => (
  <StaticImage
    src="../../../images/therapy1.jpg"
    alt={title}
    placeholder="blurred"
    layout="constrained"
    objectFit="cover"
    objectPosition="center center"
    tw="object-cover object-center h-32 md:h-36 w-full absolute top-0"
  />
);

const Image2 = ({ title }: { title: string }) => (
  <StaticImage
    src="../../../images/therapy2.jpg"
    alt={title}
    placeholder="blurred"
    layout="constrained"
    objectFit="cover"
    objectPosition="center center"
    tw="object-cover object-center h-32 md:h-36 w-full absolute top-0"
  />
);

const Image3 = ({ title }: { title: string }) => (
  <StaticImage
    src="../../../images/therapy3.jpg"
    alt={title}
    placeholder="blurred"
    layout="constrained"
    objectFit="cover"
    objectPosition="center center"
    tw="object-cover object-center h-32 md:h-36 w-full absolute top-0"
  />
);

const Image4 = ({ title }: { title: string }) => (
  <StaticImage
    src="../../../images/therapy4.jpg"
    alt={title}
    placeholder="blurred"
    layout="constrained"
    objectFit="cover"
    objectPosition="center center"
    tw="object-cover object-center h-32 md:h-36 w-full absolute top-0"
  />
);

const Therapy = ({
  data,
  imgIndex,
}: {
  data: Record<string, string>;
  imgIndex: number;
}) => {
  const { title, description } = data;

  return (
    <div tw="relative w-full">
      <div tw="w-full">
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="800"
          data-sal-easing="ease-in"
          className="text-shadow"
          tw="px-4 md:px-6 text-center bg-opacity-40 bg-black relative w-full text-xl lg:text-2xl font-bold h-32 md:h-36 flex text-white justify-center items-center z-10"
        >
          {title}
        </div>
        <div>
          {imgIndex === 0 && <Image1 title={title} />}
          {imgIndex === 1 && <Image2 title={title} />}
          {imgIndex === 2 && <Image3 title={title} />}
          {imgIndex === 3 && <Image4 title={title} />}
        </div>
      </div>
      <div tw="pb-12 pt-6 px-4 md:px-6 whitespace-pre-wrap relative">
        {description}
      </div>
    </div>
  );
};

const Therapies = ({ t }: { t: any }) => {
  return (
    <section tw="container pt-14 pb-2 mx-auto" id="therapies">
      <div tw="px-4 md:px-6">
        <Heading t={t} />
      </div>
      {t.items.map((item: Record<string, string>, index: number) => (
        <Therapy key={index} data={item} imgIndex={index} />
      ))}
    </section>
  );
};

export default Therapies;
