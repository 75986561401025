import * as React from "react";
import { Global } from "@emotion/react";
import { GlobalStyles as BaseStyles } from "twin.macro";
import Header from "./sections/Header";
import Hero from "./sections/Hero";
import AboutUs from "./sections/AboutUs";
import Cta from "./sections/Cta";
import Pricing from "./sections/Pricing";
import Testimonials from "./sections/Testimonials";
import Therapies from "./sections/Therapies";
import Videos from "./sections/Videos";
import Contacts from "./sections/Contacts";
import Footer from "./sections/Footer";
import SEO from "./sections/SEO";
import { customStyles } from "./page.styles";

const IndexPage = ({ t, lang }: { t: Record<string, any>; lang: string }) => {
  return (
    <>
      <BaseStyles />
      <Global styles={customStyles} />
      <SEO t={t.seo} lang={lang} />
      <Header t={t.header} lang={lang} />
      <main>
        <Hero t={t.hero} />
        <AboutUs t={t.about} />
        <Cta t={t.cta} />
        <Therapies t={t.therapies} />
        <Videos t={t.videos} />
        <Cta t={t.cta2} />
        <Pricing t={t.pricing} />
        <Testimonials t={t.testimonials} />
        <Contacts t={t.contacts} />
      </main>
      <Footer t={t.footer} lang={lang} />
    </>
  );
};

export default IndexPage;
