import * as React from "react";
import { Container, Title, Button } from "./styles";

const Cta = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container>
      <Title
        data-sal="fade"
        data-sal-delay="100"
        data-sal-duration="800"
        data-sal-easing="ease-in"
      >
        {t.title}
      </Title>
      <Button href="#contacts">{t.cta}</Button>
    </Container>
  );
};

export default Cta;
