import * as React from "react";
import { Container } from "./styles";

const Contacts = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="contacts">
      <div tw="relative py-14 w-full">
        <h2
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="800"
          data-sal-easing="ease-in"
          tw="font-bold text-5xl text-center flex flex-col px-4 w-full mb-10"
        >
          {t.contacts}
        </h2>
        <div tw="flex flex-wrap justify-center">
          <div tw="px-6 mt-4 mb-4 lg:mt-0">
            <h2 tw="font-semibold tracking-widest uppercase">{t.emailLabel}</h2>
            <a href={`mailto:${t.email}`} tw="text-primary leading-relaxed">
              {t.email}
            </a>
            <h2 tw="font-semibold tracking-widest uppercase mt-4">
              {t.phoneLabel}
            </h2>
            <a href={`tel:${t.phone}`} tw="text-primary leading-relaxed">
              {t.phone}
            </a>
            <h2 tw="font-semibold tracking-widest uppercase mt-4">WHATSAPP</h2>
            <a
              href={encodeURI(
                `https://wa.me/${t.whatsapp}?text=${t.whatsappText}`
              )}
              rel="noopener nofollow noreferrer"
              target="_blank"
              tw="text-primary leading-relaxed"
            >
              {t.phone}
            </a>
            <h2 tw="font-semibold tracking-widest uppercase mt-4">INSTAGRAM</h2>
            <a
              href="https://www.instagram.com/goncaloroquette/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              tw="text-primary leading-relaxed"
            >
              <span>@goncaloroquette</span>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contacts;
