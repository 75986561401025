import tw from "twin.macro";

export const Container = tw.div`
  flex flex-col text-center w-full mb-10
`;

export const Title = tw.h2`
  text-5xl font-bold
`;

export const Description = tw.p`
  mx-auto leading-relaxed mt-1 text-gray-500
  lg:w-2/3
`;

export const TitleDark = tw.h2`
  text-5xl font-bold text-white
`;

export const DescriptionDark = tw.p`
  mx-auto leading-relaxed mt-1 text-white
  lg:w-2/3
`;
